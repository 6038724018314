/* eslint-disable */
import { Options, Vue } from 'vue-class-component'
import { Action, Getter, Mutation, State } from 'vuex-class'
import { BDSM } from '@/store/bdsm/types';

const namespace: string = 'bdsm';

@Options<BdsmShowViewingNotLoginedPage>({
	watch: {
		setPages () {
			let numberOfPages = Math.ceil(this.getBDSMVideo.length / this.perPage);
			for (let index = 1; index <= numberOfPages; index++) {
				this.pages.push(index);
			}
		}
	},
	computed: {
		displayedPages () {
			return this.paginate(this.pages);
		}
	}
})

export default class BdsmShowViewingNotLoginedPage extends Vue {
	@State('bdsm') bdsm: BDSM | undefined;
	@Action('storeModelTodo', {namespace}) storeModelTodo: any;
	@Action('storeCameraPosition', {namespace}) storeCameraPosition: any;
	@Action('fetchBDSMVideo', {namespace}) fetchBDSMVideo: any;
	@Action('storeDonate', {namespace}) storeDonate: any;
	@Action('storeBDSMViewing', {namespace}) storeBDSMViewing: any;
	@Getter('getTimeLeft', {namespace}) getTimeLeft: any;
	@Getter('getBDSMVideo', {namespace}) getBDSMVideo: any;
	@Mutation('setTime', {namespace}) setTime: any;

	page: number = 1;
	perPage: number = 9;
	pages: Array<any> = [];

	toDonate(type: string) {
		switch(type) {
		case 'vip':
			this.storeDonate({
				vipDonate: true
			});
			break;
		case 'donate':
			this.storeDonate({
				donate: true
			});
			break;
		default:
			break;
		}
	}

	paginate(pages: any) {
		let page = this.page;
		let perPage = this.perPage;
		let from = (page * perPage) - perPage;
		let to = (page * perPage);
		pages = this.getBDSMVideo;
		return  pages.slice(from, to);
	}

	cameraButton(event: Event & {
		target: HTMLElement
	}) {
		const {target} = event;
		this.storeCameraPosition({
			cameraPosition: target.innerText
		});
	}

	onMainVideoFrame(type: string) {
		switch(type) {
		case 'peep':
			// this.storeModelViewingPeep({
			// 	peep: true
			// });
			break;
		case 'noLogin':
			this.$router.push({ name: 'login' }).then(r => r);
			break;
		case 'noAcc':
			this.$router.push({ name: 'create-account' }).then(r => r);
			break;
		case 'min':
			this.storeBDSMViewing({
				perMinutes: true
			})
			break;
		case 'allShow':
			this.storeBDSMViewing({
				allShow: true
			})
			break;
		default:
			break;
		}
	}

	onModelTodoButton(event: Event & {
		target: HTMLElement
	}) {
		const {target} = event;
		this.storeModelTodo({
			todoModel: target.innerText
		})
	}
	created() {
		this.fetchBDSMVideo();
	}
}
